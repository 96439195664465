import React from "react";

export default function Footer() {
  return (
    <div className="footer">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-6 col-md-2 pt-3 order-2 order-md-1">
            <h6>Manoj </h6>
          </div>
          <div className="col-12 col-md-2 pt-3 order-2 order-md-2">
            <h6>
              {" "}
              <a href="mailto:growwithmanoj@gmail.com">
                growwithmanoj@gmail.com
              </a>
            </h6>
          </div>
          <div className="col-12 col-md-2 pt-3 order-1 order-md-3 text-center">
            <img src="./Images/logo.png" alt="" />
          </div>
          <div className="col-12 col-md-2 pt-3 order-3 order-md-4">
            <h6>Community Manager</h6>
          </div>
          <div className="col-12 col-md-2 pt-3 order-3 order-md-5">
            <h6>
              <a href="tel:6383402143">+91 6383402143</a>
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
}
