import "./App.css";
import Home from "./Components/HomePage.js/Home";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import Portfolio from "./Components/Portfolio";
import Footer from "./Components/Footer";
import Contacts from "./Components/Contacts";
import About from "./Components/About";
import Navbar from "./Components/Navbar";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/portfolio" component={Portfolio} />
          <Route path="/contacts" component={Contacts} />
          <Route path="/about" component={About} />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
