import React from "react";

export default function CommunityExperience() {
  return (
    <div className="communityexperience">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center ">
            <h2>Community Experience</h2>
            <p>
              The Communities of the top Companies where I have been a part and{" "}
              <br />
              contributed as a lead or a core team member.
            </p>
          </div>
        </div>
        <div className="row justify-content-center text-center">
          <div className="col-md-3">
            <img src="./Images/gdsc.png" className="img-fluid" alt="" />
          </div>
          <div className="col-md-3">
            <img src="./Images/codeislife.png" className="img-fluid" alt="" />
          </div>
          <div className="col-md-3">
            <img src="./Images/hackerrank.png" className="img-fluid" alt="" />
          </div>
          <div className="col-md-3">
            <img src="./Images/skillenza.png" className="img-fluid" alt="" />
          </div>
          <div className="col-md-3">
            <img src="./Images/googlecloud.png" className="img-fluid" alt="" />
          </div>
          <div className="col-md-3">
            <img src="./Images/schooolofai.png" className="img-fluid" alt="" />
          </div>
          <div className="col-md-3">
            <img src="./Images/script.png" className="img-fluid" alt="" />
          </div>
          <div className="col-md-3">
            <img src="./Images/codingblocks.png" className="img-fluid" alt="" />
          </div>
          <div className="col-md-3">
            <img src="./Images/googleai.png" className="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
