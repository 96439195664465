import React from "react";
import { Link } from "react-router-dom";

export default function Seemyworks() {
  var bg = "./Images/portfoliobg.png";
  return (
    <div
      className="bg-grad"
      style={{
        backgroundImage: `linear-gradient(rgb(26 19 65 / 88%), rgb(24 20 58 / 82%)),  url(${bg})`,
      }}
    >
      <div className="container letstalk">
        <div className="row">
          <div className="col-md-12 text-center">
            <h5>Want to see My Community Contributions</h5>
            <h6>
              Communities have helped me and many people to identify their
              passion
            </h6>
            <Link to="/portfolio">My contributions</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
