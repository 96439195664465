import React from "react";
import ScrollToTop from "./ScrollToTop";

export default function About() {
  var bg = "./Images/about.jpg";
  return (
    <div>
      <ScrollToTop />
      <div
        className="about-details"
        style={{
          backgroundImage: ` url(${bg})`,
        }}
      >
        <div className="container">
          <div className="row white">
            <div className="col-md-3">
              <small>About me</small>
              <h1>My Journey</h1>
            </div>
            <div className="col-md-9">
              <p>
                Communities have helped me to identify my passion and know about
                various career opportunities. It helped me to learn and share my
                knowledge to from different people creating an impact. Strong
                communities are critical because they're often an important
                source of social connection and a sense of belonging.
              </p>
              <div className="row">
                <div className="col-md-6">
                  <h1>About me</h1>
                  <p>
                    A person who loves to solve user problems which will have a
                    great impact on business and makes user life easier. I have
                    worked with several startups from Stealth, Seed, Series A
                    funding, and Bootstrapped companies.
                  </p>
                </div>
                <div className="col-md-6">
                  <h1>Community Management</h1>
                  <p>
                    I am a Community enthusiast. I have been part of several
                    communities like Google developer student clubs, Microsoft
                    Learn Student Partner, Google Developer Groups (Cloud)
                    Coimbatore, Script Foundation, Explore ML
                    Facilitator,Hackerearth Developer community and Codingblocks
                    student program
                  </p>
                </div>
              </div>
              <div className="profile text-center">
                <img
                  src="./images/manoj.jpg"
                  className="img-fluid"
                  alt="manoj"
                />
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-3">
              <small>How do you work Manoj?</small>
              <h1>My Principles</h1>
            </div>
            <div className="col-md-9">
              <p>
                I like to add value to the business and all the members or the
                loyal users of the community and the product. My goal is to
                create a valuable impact as a result both the business and the
                community gets benefited.
              </p>
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="home-tab"
                    data-toggle="tab"
                    href="#community"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    1. Understanding the business
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#teambuilding"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    2. Users
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="contact-tab"
                    data-toggle="tab"
                    href="#marketing"
                    role="tab"
                    aria-controls="contact"
                    aria-selected="false"
                  >
                    3. Community building
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="contact-tab"
                    data-toggle="tab"
                    href="#roadmap"
                    role="tab"
                    aria-controls="contact"
                    aria-selected="false"
                  >
                    4. Community Management
                  </a>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="community"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  The first and most important thing that most people don’t get
                  is servicing the customer. To be in business long term, you
                  have to take care of your customers. You have to find a group
                  of people who want to buy your product and become a brand
                  evenagelists and advocates for your product.
                </div>
                <div
                  class="tab-pane fade"
                  id="teambuilding"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  I want to understand the people who use our service/product
                  (our users) and what they want to do (their user needs) in
                  order to build a community that works for them. You need to
                  understand users and their needs from their point of view and
                  not solely through the lens of the product/service so that we
                  have a mutual benefit.
                </div>
                <div
                  class="tab-pane fade"
                  id="marketing"
                  role="tabpanel"
                  aria-labelledby="contact-tab"
                >
                  Impactful communities add value when they support members,
                  product/service they are using,provide a sense of ownership,
                  make strategic connections, and act as a source for continuous
                  product improvements based on their feedback.
                </div>
                <div
                  class="tab-pane fade"
                  id="roadmap"
                  role="tabpanel"
                  aria-labelledby="contact-tab"
                >
                  Community management not only helps give brands a much deeper
                  understanding of their customers needs and desires, but it
                  also catalyzes user-generated content, like reviews and
                  comments, which are seen as more authentic than direct brand
                  efforts.
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-3">
              <small>What do i use?</small>
              <h1>Tools </h1>
            </div>
            <div className="col-md-9">
              <p>
                Technology is enabling people to get connected online and share
                their views Tools/Products I use to build, engage and create
                content for the community are
              </p>
              <div className="row logos ">
                <img src="./Images/canva.png" className="img-fluid" alt="" />
                <img src="./Images/discord.png" className="img-fluid" alt="" />
                <img src="./Images/figma.png" className="img-fluid" alt="" />
                <img
                  src="./Images/googleform.png"
                  className="img-fluid"
                  alt=""
                />
                <img
                  src="./Images/mailchimp.png"
                  className="img-fluid"
                  alt=""
                />
                <img src="./Images/medium.png" className="img-fluid" alt="" />
                <img
                  src="./Images/peppertype.png"
                  className="img-fluid"
                  alt=""
                />
                <img src="./Images/publer.png" className="img-fluid" alt="" />
                <img
                  src="./Images/streamyard.png"
                  className="img-fluid"
                  alt=""
                />
                <img src="./Images/luma.png" className="img-fluid" alt="" />
              </div>
            </div>
          </div>

          <div className="row mt-5 mb-5">
            <div className="col-md-3">
              <small>FAQ</small>
              <h1>Do you have any Questions?</h1>
            </div>
            <div className="col-md-9">
              <p></p>
              <div id="accordion">
                <div class="card">
                  <div class="card-header" id="headingOne">
                    <h5 class="mb-0">
                      <button
                        class="btn btn-link text-left"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Are you available for Contract and freelance based roles
                        ?
                      </button>
                    </h5>
                  </div>

                  <div
                    id="collapseOne"
                    class="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordion"
                  >
                    <div class="card-body">Yes, I am available</div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="headingTwo">
                    <h5 class="mb-0">
                      <button
                        class="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        How can I contact you?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapseTwo"
                    class="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordion"
                  >
                    <div class="card-body">
                      Please send an email to growwithmanoj@gmail.com or use the
                      chatbot
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="headingThree">
                    <h5 class="mb-0">
                      <button
                        class="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Can I book a quick call?
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapseThree"
                    class="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordion"
                  >
                    <div class="card-body">
                      Yes use this{" "}
                      <a
                        className="font-weight-bold"
                        href="https://calendly.com/growwithmanoj/15min"
                      >
                        calendly link
                      </a>{" "}
                      to book a 15 min quick call
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
