import React from "react";

export default function CommunityEvents() {
  return (
    <div className="communityevents">
      <div className="container">
        <div className="row">
          <div className="col-md-7 align-self-center">
            <h4>Learn With Community Event</h4>
            <p>
              Founder and Organizer of Learn with Community Event one of the
              Largest Webinar Series were Various Communities Participated.
            </p>
            <a
              href="https://www.youtube.com/c/DSCSKCT/videos?view=0&sort=da&flow=grid"
              target="_blank"
            >
              View Event
            </a>
          </div>
          <div className="col-md-5 mt-4 mt-md-0">
            <div className="row">
              <div className="col-5">
                <h6>10+</h6>
                <h5>Communities</h5>
              </div>
              <div className="col-5">
                <h6>13+</h6>
                <h5>Speakers</h5>
              </div>
            </div>
            <div className="row">
              <div className="col-5">
                <h6>5000+</h6>
                <h5>Students</h5>
              </div>
              <div className="col-5">
                <h6>400+</h6>
                <h5>Member Support</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
