import React from "react";
import { Link } from "react-router-dom";

export default function Herosection() {
  var bg = "./Images/heroimage.png";

  return (
    <div
      style={{
        backgroundImage: ` linear-gradient(rgb(30 26 51 / 42%), rgb(30 26 51 / 63%)), url(${bg})`,
      }}
      className="hero-section"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="hero-content text-left">
              <h6>I am Manoj a Community Man</h6>
              <h1>
                Impacting People <br /> Building Communitites
              </h1>
              <h5>
                United We can, Divided we Fall. Lets do things together to
                achieve success and reach our goal. I believe "Community is
                Power"
              </h5>
              <a
                target="_blank"
                href="https://calendly.com/growwithmanoj/15min"
              >
                Lets talk
              </a>
              <Link className="knowmore" to="/about">
                Know more
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
