import React from "react";

export default function Communitymanager() {
  return (
    <div className="communitymanager">
      <div className="container">
        <div className="row">
          <div className="col-md-6 align-self-center">
            <h4>Are You looking for a Community Manager</h4>
            <p>
              Nowadays lots of companies have a good customer base but have a
              problem of retention, growth, engagement and satisfaction. The
              only one solution is to build a community and make people engage
              with each other and can gather user feedback.
            </p>
            
            <ul>
              <li>Organize Events</li>
              <li>Manage Programs</li>
              <li>Content Creation</li>
              <li>Retention and Growth</li>
            </ul>
          </div>
          <div className="col-md-6 text-center">
            <img src="./Images/community.jpg" className="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
