import React from "react";

export default function Portfolio() {
  var bg = "./Images/generalbg.jpg";
  return (
    <div>
      <div
        className="portfolio"
        style={{
          backgroundImage: `  linear-gradient(rgb(73 59 147 / 98%), rgb(34 33 43 / 80%)), url(${bg})`,
        }}
      ></div>
      <div className="portfolio-details">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="text-center">
                <h1>A collection of my community works since 2018.</h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <img
                src="./Images/portfolio4.jpeg"
                className="img-fluid"
                alt=""
              />
              <h6>Effective usage of LinkedIn</h6>
              <p>
                A session about Effective usage of LinkedIn on how to use
                Linkedin to get jobs,clients and also how to create the all star
                profile on Linkedin
              </p>
              <a target="_blank" href="https://youtu.be/Xfve1XlO3Sk">
                Watch on Youtube
              </a>
            </div>
            <div className="col-md-6">
              <img
                src="./Images/portfolio3.jpeg"
                className="img-fluid"
                alt=""
              />
              <h6>Explore Career Opppurtunities</h6>
              <p>
                Organized the guiding talk series inviting the best people in
                the industry on understanding what are the different
                opportunities available for students and how one can achieve
                their career goal they are passionate about.
              </p>
              <a target="_blank" href="https://youtu.be/Xym16yFQErg">
                Watch on Youtube
              </a>
            </div>
          </div>
          <div className="row mt-5 mb-5">
            <div className="col-md-6">
              <img src="./Images/portfolio5.png" className="img-fluid" alt="" />
              <h6>Learn to Grow blog</h6>
              <p>
                Learn to grow is a blog is to share knowledge about recent
                technologies and trends and we also help students by providing
                them with free resources and certification courses.
              </p>
              <a
                target="_blank"
                href="https://learntogrowbymanoj.blogspot.com/"
              >
                Website Link
              </a>
            </div>
            <div className="col-md-6">
              <img
                src="./Images/portfolio1.jpeg"
                className="img-fluid"
                alt=""
              />
              <h6>Communities Build Us (Tamil)</h6>
              <p>
                "Alone we can do so little, together we can do much", according
                to Hellen Keller's words he's ardent lover in Community.
                Mr.Manoj will be speaking about "Communities build us".
              </p>
              <a target="_blank" href="https://fb.watch/a0sZzpK25q/">
                View more
              </a>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <h2>More Info Coming Soon 🚀🤩🥳</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
