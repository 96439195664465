import React from "react";

export default function Highlights() {
  return (
    <div className="highlights">
      <div className="container">
        <div className="row">
          <div className="col-6 col-lg-3 mt-3">
            <img src="./Images/build.svg" alt="" />
            <h6>Build</h6>
          </div>
          <div className="col-6 col-lg-3 mt-3">
            <img src="./Images/engage.svg" alt="" />
            <h6>Engage</h6>
          </div>
          <div className="col-6 col-lg-3 mt-3">
            <img src="./Images/grow.svg" alt="" />
            <h6>Grow</h6>
          </div>
          <div className="col-6 col-lg-3 mt-3">
            <img src="./Images/enhance.svg" alt="" />
            <h6>Enhance</h6>
          </div>
        </div>
      </div>
    </div>
  );
}
