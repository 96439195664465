import React from "react";

export default function Testimonal() {
  return (
    <div className="testimonal">
      <div className="container">
        <div className="row ">
          <div className="col-12">
            <h1>Testimonal</h1>
            <h6>Lets see what leaders, members and students say about me</h6>
          </div>
        </div>
        <div className="row justify-content-around">
          <div className="col-md-3 mt-5 ">
            <p className="bg-white">
              Manoj worked with GDG Cloud Coimbatore and on few projects with my
              team. He is an inspirational person with lot of insights in his
              head. For sure he brings a lot of different perspectives and ideas
              to the table be it for the community or for the business.
            </p>
            <div className="row mt-4">
              <div className="col-4">
                <img src="./Images/Praveen.jpeg" className="img-fluid" alt="" />
              </div>
              <div className="col-8 align-self-center">
                <h5>Praveen Thirumurugan,</h5>
                <h4>Lead - GDG Cloud Coimbatore </h4>
              </div>
            </div>
          </div>
          <div className="col-md-3 mt-5">
            <p className="bg-white">
              Manoj got a level of dedication which is highly commendable, His
              keen interest in understanding the product & mapping the user
              journey is helping Merge intern to develop the product in a better
              direction. Feeling proud to have Manoj in our team.
            </p>
            <div className="row mt-4">
              <div className="col-4">
                <img src="./Images/Dheeraj.jpeg" className="img-fluid" alt="" />
              </div>
              <div className="col-8 align-self-center">
                <h5>Dheeraj Mehndiratta,</h5>
                <h4>Founder & CEO: Wrky.ai</h4>
              </div>
            </div>
          </div>
          <div className="col-md-3 mt-5">
            <p className="bg-white">
              He has written tutorials on CSEstack.org. He hones different
              programming skills, and eager to dwell into new technologies. He
              is very passionate to know what's going around and looks forward
              to give back to the community. Kudos!
            </p>
            <div className="row mt-4">
              <div className="col-4">
                <img
                  src="./Images/anirudha.jpeg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-8 align-self-center">
                <h5>Aniruddha Chaudhari,</h5>
                <h4>Founder - csestack.org</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
