import React from "react";

export default function Contacts() {
  var bg = "./Images/generalbg.jpg";
  return (
    <div>
      <div
        className="contact"
        style={{
          backgroundImage: `  linear-gradient(rgb(73 59 147 / 98%), rgb(34 33 43 / 80%)), url(${bg})`,
        }}
      ></div>
      <div className="contact-details">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="text-center">
                <h1>
                  Looking for a Community builder? <br /> I can help you
                </h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.30027672842!2d76.9585752146575!3d11.016082492159152!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba859fb11dcae5f%3A0x54cce4a830873bee!2sRamani%20Radhey%20Shyam!5e0!3m2!1sen!2sin!4v1643950601669!5m2!1sen!2sin"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-4">
              <small>Contact information</small>
              <h3>Let’s connect</h3>
            </div>
            <div className="col-md-8">
              <p>
                I am from India living in the beautiful city Coimbatore. I have
                helped startups in USA,UAE,Canada and Australia in Growth,
                Product and Community consulting
              </p>
              <div className="row mt-5">
                <div className="col-md-12 mb-4">
                  <h6>Reach me via</h6>
                  <h5>
                    Phone :{" "}
                    <a href="tel:6383402143">
                      <span>+91 6383402143</span>
                    </a>
                  </h5>
                  <h5>
                    Email :
                    <a href="mailto:growwithmanoj@gmail.com">
                      <span> growwithmanoj@gmail.com</span>
                    </a>
                  </h5>

                  <a href="https://rmanoj.medium.com/" target="_blank">
                    <span
                      class="iconify"
                      data-icon="akar-icons:medium-fill"
                    ></span>
                  </a>

                  <a
                    href="https://www.instagram.com/rockingmanoj888/"
                    target="_blank"
                  >
                    <span
                      class="iconify"
                      data-icon="bx:bxl-instagram-alt"
                    ></span>
                  </a>

                  <a
                    href="https://www.linkedin.com/in/r-manoj/"
                    target="_blank"
                  >
                    <span
                      class="iconify"
                      data-icon="akar-icons:linkedin-fill"
                    ></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
