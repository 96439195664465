import React from "react";
import { Link } from "react-router-dom";
import $ from "jquery";

export default function Navbar() {
  $(".navbar-nav .nav-link").on("click", function () {
    $(".navbar-nav .nav-link").removeClass("active");
    $(this).addClass("active");
  });

  return (
    <div>
      <nav class="navbar navbar-expand-lg navbar-dark fixed-top navbar-fixed-top">
        <div class="logo-nav01">
          <Link to="/">
            <img src="./Images/logo.png" alt="" />{" "}
          </Link>
        </div>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item ">
              <Link class="nav-link" to={{ pathname: "/" }}>
                Home
              </Link>
            </li>
            <li class="nav-item ">
              <Link class="nav-link" to={{ pathname: "/about" }}>
                About Me
              </Link>
            </li>

            <li class="nav-item">
              <Link class="nav-link" to={{ pathname: "/portfolio" }}>
                My Works{" "}
              </Link>
            </li>
            <li class="nav-item">
              <Link class="nav-link" to={{ pathname: "/contacts" }}>
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </nav>

      {/* <header>
        <nav class="header-nav01">
          <div class="logo-nav01">
            {" "}
            <img src="./Images/logo.svg" alt="" />{" "}
          </div>
          <input type="checkbox" id="click-nav01" />
          <label for="click-nav01" class="menu-btn-nav01">
            <i class="fas fa-bars"></i>
          </label>
          <ul>
            <li class="">
              <Link to={{ pathname: "/" }}>Home</Link>
            </li>
            <li class="">
              <Link to={{ pathname: "/about" }}>About Me</Link>
            </li>
            <li class="">
              <Link to={{ pathname: "/portfolio" }}>My Works </Link>
            </li>
            <li class="">
              <Link to={{ pathname: "/contacts" }}>Contact</Link>
            </li>
          </ul>
        </nav>
      </header> */}
    </div>
  );
}
