import React from "react";
import Herosection from "./Herosection";
import Highlights from "./Highlights";
import CommunityEvents from "./CommunityEvents";
import CommunityExperience from "./CommunityExperience";
import Communitymanager from "./Communitymanager";
import Testimonal from "./Testimonal";
import ScrollToTop from "../ScrollToTop";
import Seemyworks from "./Seemyworks";

export default function Home() {
  return (
    <div>
      <ScrollToTop />
      <Herosection />
      <Highlights />
      <Communitymanager />
      <CommunityExperience />
      <CommunityEvents />
      <Testimonal />
      <Seemyworks />
    </div>
  );
}
